
/**
 * This is the maintenance view when the authorization service return that the system
 is in maintenance mode.
 */
import { hasMaintenanceAccessRight } from "@/main";
import Vue from "vue";
import { Component } from "vue-property-decorator";

import { SystemSettings } from "@/services/SystemSettings";

@Component({
  name: "MaintenanceModeActive"
})
export default class MaintenanceModeActive extends Vue {
  /*----------  Props  ----------*/
  async created() {
    let maintenanceMode = await SystemSettings.getSystemConfig();
    if (maintenanceMode.status === 202) {
      if (maintenanceMode.data.maintenanceMode && !hasMaintenanceAccessRight()) {
        setTimeout(() => {
          const url = this.$keycloak.createLogoutUrl?.() || "";
          console.log(url);
          window.location.replace(url);
        }, 10000);
      } else {
        this.$router.push("/");
      }
    }
  }
}
